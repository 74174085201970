import React, { useState } from 'react';
import { Modal, Typography, Box, TextField, Button, Snackbar, Alert } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import styles from './ModalEmail.module.css';
import { isBrowser, isMobile } from 'react-device-detect';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDdHGA4AiahTh8Lhc84lHx-PeGk21UViLY",
  authDomain: "habithustlesubscribers.firebaseapp.com",
  projectId: "habithustlesubscribers",
  storageBucket: "habithustlesubscribers",
  messagingSenderId: "441166103661",
  appId: "1:441166103661:web:8b13ef90eb68af7d846b64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ModalEmail = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lowerCaseEmail = email.toLowerCase(); // Convert email to lowercase
    if (validateEmail(lowerCaseEmail)) {
      try {
        const subscribersRef = doc(db, 'newsLetterSubscribers', 'subscribers');
        const docSnap = await getDoc(subscribersRef);

        if (docSnap.exists()) {
          const subscribersData = docSnap.data();
          const existingEmails = subscribersData.emails || [];

          if (!existingEmails.includes(lowerCaseEmail)) {
            await updateDoc(subscribersRef, {
              emails: arrayUnion(lowerCaseEmail)
            });
            setSuccessMessage("Subscription successful!");
            setSnackbarOpen(true);
            setEmail("");
            setEmailError("");
            handleClose();
          } else {
            setEmailError("This email is already subscribed.");
          }
        } else {
          await updateDoc(subscribersRef, {
            emails: arrayUnion(lowerCaseEmail)
          });
          setSuccessMessage("Subscription successful!");
          setSnackbarOpen(true);
          setEmail("");
          setEmailError("");
          handleClose();
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase()); // Convert email to lowercase
    if (emailError) {
      setEmailError("");
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box className={isMobile ? styles.modalContainerMob : styles.modalContainer}>
          <Typography id="modal-modal-title" variant={isMobile ? "h8" : "h6"} component={isMobile ? "h8" : "h2"} className={styles.modalTitle}>
            Sign Up for Newsletter
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              className={styles.modalInput}
            />
            <Button type="submit" variant="contained" color="primary" className={styles.modalButton}>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }
        }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalEmail;
