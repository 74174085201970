import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
import HeroSection from "./pages/HeroSection";
import IPhone1415ProMax from "./pages/IPhone1415ProMax";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "HabitHustle";  // Add appropriate title
        metaDescription = "Welcome to HabitHustle";  // Add appropriate description
        break;
      default:
        title = "HabitHustle";  // Add default title
        metaDescription = "Welcome to HabitHustle";  // Add default description
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={
        <>
          <BrowserView>
            <HeroSection />
          </BrowserView>
          <MobileView>
            <IPhone1415ProMax />
          </MobileView>
        </>
      } />
    </Routes>
  );
}

export default App;
