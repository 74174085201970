import React, { useState } from "react";
import { Button, Modal, Typography, Box, TextField } from "@mui/material";
import styles from "./HeroSection.module.css";
import ModalEmail from "../components/ModalEmail";

const HeroSection = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={styles.heroSection}>
      <div className={styles.logoParent}>
        <div className={styles.logo}>
          <img className={styles.carbonfinanceIcon} alt="" />
          <div className={styles.risefinance}>HabitHustle</div>
        </div>
        <img className={styles.logoIcon} alt="" src="/logo_trans.png" />
      </div>
      <div className={styles.card1bg} />
      <div className={styles.card1bg1} />
      <div className={styles.frameParent}>
        <div className={styles.followYourHabitsAndEarnParent}>
          <div className={styles.followYourHabitsContainer}>
            <span className={styles.followYourHabitsContainer1}>
              <span
                className={styles.followYourHabits}
              >{`Follow your Habits and `}</span>
              <span>earn</span>
            </span>
          </div>
          <div className={styles.betOnYourself}>
            Bet on yourself and transform your life with HabitHustle! Commit to
            a 7, 14, or 21-day challenge in sleep, cardio, or gym. Track your
            progress, submit proof, and get your money back! Climb the
            leaderboard and win cash prizes.
          </div>
        </div>
        <Button
          className={styles.button}
          disableElevation={true}
          color="primary"
          variant="outlined"
          sx={{ borderRadius: "0px 0px 0px 0px", width: 244.4, marginLeft: 0.5 }}
          onClick={handleOpen}
        >
          SIGN UP FOR NEWSLETTER
        </Button>
      </div>
      <div className={styles.div}>
        <img className={styles.iphone13Icon} alt="" src="/iphone13@2x.png" />
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.png" />
      </div>
      <div className={styles.iconsocialinstagramParent}>
        <a href="https://www.instagram.com/habithustle.app/" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.iconsocialinstagramCopy3}
            alt="n"
            src="/iconsocialinstagram@2x.png"
          />
        </a>
        <a href="https://your-link-2.com" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.iconsocialinstagramCopy}
            alt=""
            src="/iconsocialinstagram-copy@2x.png"
          />
        </a>

        <a href="https://www.linkedin.com/company/habithustle/" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.iconsocialinstagramCopy2}
            alt=""
            src="/iconsocialinstagram-copy-3@2x.png"
          />
        </a>
      </div>

      <div className={styles.component1}>
        <div className={styles.comingSoonOn}>coming soon on</div>
        <img
          className={styles.googleStoreImgIcon}
          alt=""
          src="/googlestoreimg@2x.png"
        />
        <img
          className={styles.appleStoreImgIcon}
          alt=""
          src="/applestoreimg@2x.png"
        />
      </div>
      
      <ModalEmail open={open} handleClose={handleClose} />
    </div>
  );
};

export default HeroSection;
