import React, { useState } from "react";
import { Button } from "@mui/material";
import ModalEmail from "../components/ModalEmail";
import styles from "./IPhone1415ProMax.module.css";

const IPhone1415ProMax = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={styles.iphone1415ProMax1}>
      <div className={styles.card1bg} />
      <div className={styles.card1bg1} />
      <div className={styles.logoParent}>
        <div className={styles.logo}>
          <div className={styles.risefinance}>HabitHustle</div>
        </div>
        <img className={styles.logoIcon} alt="" src="/logo_trans.png" />
      </div>
      <div className={styles.followYourHabitsContainer}>
        <span className={styles.followYourHabitsContainer1}>
          <p className={styles.followYourHabits}>Follow your Habits</p>
          <p className={styles.andEarn}>
            <span className={styles.and}>{`and `}</span>
            <span className={styles.earn}>earn</span>
          </p>
        </span>
      </div>
      <div className={styles.betOnYourselfAndTransformParent}>
        <div className={styles.betOnYourselfContainer}>
          <p className={styles.andEarn}>
            {" "}
            Take on a 7, 14, or 21-day challenge in sleep, cardio, or gym.
          </p>
          <p className={styles.andEarn}>&nbsp;</p>
          <p
            className={styles.andEarn}
          >{` Track your progress, submit proof, and earn rewards! `}</p>
          <p className={styles.andEarn}>&nbsp;</p>
          <p className={styles.andEarn}>
            Climb the leaderboard and win cash prizes. Bet on yourself today!
          </p>
        </div>
        <Button
          className={styles.button}
          disableElevation={true}
          color="primary"
          variant="outlined"
          sx={{ borderRadius: "0px 0px 0px 0px", width: 243, height: 48 }}
          onClick={handleOpen}
        >
          SIGN UP FOR NEWSLETTER
        </Button>
        <img className={styles.frameChild} alt="" src="/1@2x.png" />
        <div className={styles.component2}>
          <div className={styles.comingSoonOn}>coming soon on</div>
          <img
            className={styles.googleStoreImgIcon}
            alt=""
            src="/googlestoreimg@2x.png"
          />
          <img
            className={styles.appleStoreImgIcon}
            alt=""
            src="/applestoreimg@2x.png"
          />
        </div>
        <div className={styles.iconsocialinstagramParent}>
          <a
            href="https://www.instagram.com/habithustle.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.iconsocialinstagramCopy3}
              alt="n"
              src="/iconsocialinstagram@2x.png"
            />
          </a>
          <a
            href="https://your-link-2.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.iconsocialinstagramCopy}
              alt=""
              src="/iconsocialinstagram-copy@2x.png"
            />
          </a>

          <a
            href="https://www.linkedin.com/company/habithustle/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.iconsocialinstagramCopy2}
              alt=""
              src="/iconsocialinstagram-copy-3@2x.png"
            />
          </a>
        </div>
      </div>
      <ModalEmail open={open} handleClose={handleClose} />
    </div>
  );
};

export default IPhone1415ProMax;
